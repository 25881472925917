@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap");
body {
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif; }

.c-form {
  background-color: #fff;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1); }

.c-form__content-top {
  background: #0CB121;
  padding: 30px 15px; }

.c-form__title {
  text-align: center;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2; }

.c-form__content {
  padding: 20px 20px 0px 20px; }

.c-form__item {
  width: 100%;
  margin-bottom: 20px; }

.c-form__label {
  width: 100%;
  position: relative; }

.c-form__input-title {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #333; }

.input__tel,
.input__text {
  width: 100%;
  padding: 10px;
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px; }

.input__tel.\--error,
.input__text.\--error {
  border-color: #f99; }

.c-form__input-error {
  position: absolute;
  bottom: -22px;
  right: 0;
  line-height: 1;
  font-size: 12px;
  color: #f99; }

.c-form__checkbox {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px; }

.c-form__checkbox input[type=checkbox] {
  margin-right: 4px; }

.c-form__btn {
  background: #0CB121;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #FFF;
  padding: 10px;
  border: none;
  width: 100%;
  text-transform: uppercase; }

.c-form__conf {
  padding: 5px 20px 20px 20px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 10px;
  justify-content: center; }

.c-form__conf--icon {
  margin-right: 5px; }

.c-modal-form {
  padding: 0px;
  width: 100%;
  max-width: 333px; }

.c-modal-form .fancybox-button svg {
  color: #FFF; }

.formFooter .c-form__content-top {
  background-color: transparent;
  padding-top: 20px;
  padding-bottom: 20px; }

.formFooter .c-form__content-top .c-form__title {
  color: #333;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.3; }

.formFooter .c-form__title span {
  text-transform: uppercase;
  color: #0CB121; }

.c-modal {
  position: relative;
  z-index: 1000000; }

.c-modal__container {
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: 0;
  background: #0000005a;
  top: 0;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%; }

.c-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 55rem;
  height: 100%;
  margin: auto;
  padding: 0 2rem; }

.c-message__item {
  padding: 40px 80px 40px 40px;
  display: flex;
  border-radius: 2px;
  background-color: #fff;
  position: relative; }

.c-message__item--close {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
  opacity: .3;
  cursor: pointer; }

.c-message__wrap-img {
  margin-right: 35px;
  max-width: 120px; }

.c-message__img {
  min-width: 100px; }

.c-message__title {
  font-weight: 400;
  line-height: 25px;
  font-size: 16px;
  color: #515255; }

.c-message__text {
  margin-top: 10px;
  font-weight: 400;
  line-height: 15px;
  font-size: 12px;
  color: #949699; }

.c-message__msg {
  margin-top: 10px; }

/*

.c-form {
    max-width: 430px;
    width: 100%;
    color: #fafafa;
    // box-shadow: 0 2px 4px rgb(0 0 0 / 24%), 0 4px 16px rgb(0 0 0 / 16%);
    border-radius: 2px;
    text-align: left;
}

.c-form.\--white {
    background-color: #fff;
    color: #222;
}


.c-form__title {
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    padding: 25px 10px;
    background-color: $accent-color
}



@media (max-width: 1024px) {
    .c-form__title{
        font-size: 18px;
    }
}

.c-form__descr {
    margin-top: 30px;
    color: #bcbfc4;
}

.c-form__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
}

.c-form__item:not(:first-child){
    margin-top: 20px;
}

.c-form__label {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 25px;
    font-size: 1rem;
    color: #2e3033;
    position: relative;
    margin-bottom: 0;
}

.c-form__input {
    padding: 8px 10px;
    margin-top: 5px;
    border: 1px solid #e1e2e6;
    background-color: #f2f3f5;
    border-radius: 2px;
    // box-shadow: inset 1px 1px 3px rgb(0 0 0 / 10%);
}

.c-form__input-error{
    position: absolute;
    bottom: -23px;
    right: 0;
    line-height: 1.2;
    font-size: 12px;
    color: #f99;
}

.c-form__content--nonpad .form_button {
    padding: 0 30px;
}

.c-form__btn {
    width: 100%;
    color: #fff;
    font-weight: 400;
    line-height: 1rem;
    font-size: 1rem;
    text-align: center;
    padding: 10px 5px;
    text-decoration: none;
    transition: all .2s;
    cursor: pointer;
    border: 1px solid #96151A;
    background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
    border-radius: 5px;
}

.c-form__btn:hover,
.c-form__btn:focus{
    background-color: transparent;
    color: $accent-color;
}

.c-form__conf {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: normal;
    font-size: 10px;
    color: #bcbfc4;
}

.section-promo__wrap-form {
    position: relative;
    z-index: 98;
    display: flex;
    justify-content: center;
}

#modal-callback.fancybox-content, 
#modal-callback .c-form__content
{
    padding: 0px;
}
.c-modal-form .c-form__label{
    padding: 20px;
    padding-bottom: 0px;
}


.c-modal-form .form_button{
    padding: 20px
}
.c-modal-form .c-form__conf{
    margin-top: 0px;
    padding-bottom: 20px;
    
}
// #modal-callback .c-form__item{
//     padding: 20px 0px;
// }




// @media (max-width: 992px) {

//     .section-promo .section-promo__wrap-form{
//         display: none;
//     }

// }

*/
.header-mobile {
  padding: 10px 20px;
  box-shadow: 0 0 20px 0 #bcbfc4;
  background-color: #FFF; }

.block-fixed {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 400; }

.header-mobile__contact-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header-mobile__burger-menu {
  cursor: pointer;
  position: relative;
  width: 35px;
  height: 25px; }

.header-mobile__burger--global {
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  border-top: 2px solid #1483C8;
  width: 100%;
  transition: 0.55s; }

.header-mobile__burger-top {
  top: 0; }

.header-mobile__burger-middle {
  top: 50%;
  transform: translateY(-50%); }

.header-mobile__burger-bottom {
  bottom: 0px; }

.header-mobile__burger-menu.active .header-mobile__burger--global {
  backface-visibility: hidden;
  transition: 0.2s 0.2s; }

.header-mobile__burger-menu.active .header-mobile__burger-top {
  top: 15px;
  transform: rotate(50deg); }

.header-mobile__burger-menu.active .header-mobile__burger-bottom {
  top: 15px;
  bottom: auto;
  transform: rotate(-410deg); }

.header-mobile__burger-menu.active .header-mobile__burger-middle {
  opacity: 0; }

.header-mobile__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.header-mobile__logo {
  flex: 1;
  max-width: 30%; }

.header-mobile__inner-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100px; }

.header-mobile__inner-phone {
  flex-basis: 30px;
  position: relative; }

.header-mobile__phone {
  display: inline-block;
  font-size: 1px;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  opacity: 0; }

.header-mobile__inner-phone svg {
  width: 30px;
  height: 30px; }

.header-mobile__mail {
  flex-basis: 30px; }

.header-mobile__mail svg {
  width: 30px;
  height: 30px; }

.header-mobile__link-img {
  width: 2.2rem; }

.header-mobile__list {
  list-style-type: none;
  margin: 2rem 0 0; }

.header-mobile__list-item:not(:first-child) {
  margin-top: 1rem; }

.header-mobile__link {
  text-decoration: none;
  color: #2e3033; }

.header-mobile__link:active,
.header-mobile__link:focus,
.header-mobile__link:hover {
  text-decoration: none;
  color: #626366; }

.header-mobile__burger {
  cursor: pointer; }

.header-mobile--mobile {
  padding-top: 20px; }

.header.is-clone {
  position: fixed;
  top: -10rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  transition: .5s;
  padding: 10px 0;
  background-color: #FFF;
  box-shadow: 0 4px 16px 0 rgba(154, 147, 140, 0.5); }

.header.is-fixed {
  top: 0 !important; }

.header__contact--clone {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.header__menu {
  width: 50%; }

.header__list {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__link {
  border-bottom: 1px dashed #FFF; }

.header__link:hover,
.header__link:focus {
  color: #0CB121;
  text-decoration: none;
  border-color: #0CB121; }

.header.is-clone .header__phone {
  color: #333; }

.header.is-clone .header__phone:hover,
.header.is-clone .header__phone:focus {
  color: #0CB121; }

.header__btn--clone {
  cursor: pointer;
  border: solid 1px #0F1A34;
  color: #0F1A34;
  background-color: transparent;
  text-align: center;
  padding: 5px 10px;
  text-decoration: none;
  transition: all .2s; }

.header__btn--clone:hover,
.header__btn--clone:focus {
  background-color: #0F1A34;
  color: #FFF;
  text-decoration: none; }

@media (max-width: 1024px) {
  .header__btn--clone {
    padding: 5px;
    font-size: 14px; } }

.header {
  padding: 10px 0px;
  background-color: transparent;
  position: relative;
  z-index: 2;
  margin-bottom: 60px; }

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__wraper-logo {
  flex-basis: 15%;
  width: 15%; }

.header-icon__wrapper {
  flex-basis: 50%;
  width: 50%;
  display: flex;
  align-items: center; }

.header-icon____item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.header-icon__text {
  text-align: center;
  font-size: 12px; }

.header-contact__wrapper {
  flex-basis: 230px;
  width: 230px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.header__contact {
  text-align: right; }

.header__phone {
  font-size: 24px;
  color: #FFF; }

.header__phone:hover,
.header__phone:focus {
  color: #0CB121;
  text-decoration: none; }

.header-btn__link {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-decoration: solid;
  border-bottom: 1px dashed; }

.header-btn__link:hover,
.header-btn__link:focus {
  text-decoration: none;
  color: #0CB121;
  border-color: #0CB121; }

@media (max-width: 1024px) {
  .header__menu {
    flex-basis: 52%;
    width: 52%; }
  .header__link {
    font-size: 13px; }
  .header__contact {
    display: flex;
    flex-direction: column; }
  .header__list-item {
    font-size: 14px; } }

.section-promo {
  position: relative;
  background-size: cover;
  padding: 40px 0px;
  padding-top: 0px;
  color: #fff; }

.section-promo::before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%; }

.section-promo__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 2; }

.section-promo__content {
  flex-basis: 70%;
  min-height: 150px; }

.section-promo__title {
  font-size: 40px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  line-height: 1.2;
  text-shadow: 0px 4px 80px rgba(0, 0, 0, 0.8);
  letter-spacing: 0.06em; }

.section-promo__title span {
  font-size: 65px;
  letter-spacing: 0.01em;
  display: block; }

.section-promo__subtitle {
  font-size: 21px;
  text-transform: uppercase;
  color: #F2F3F5; }

.section-promo__desc {
  width: 80%;
  margin-top: 10px;
  display: block;
  background-color: #1483C8;
  color: #FFF;
  padding: 10px 20px;
  line-height: 1;
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); }

.promo-utp__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.promo-utp__item {
  flex-basis: 19%;
  display: flex;
  align-items: center;
  flex-direction: column; }

.promo-utp__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; }

.promo-utp__icon svg {
  overflow: hidden;
  vertical-align: middle; }

.promo-utp__text {
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0px 15px; }

.section-promo__form {
  flex-basis: 30%; }

.section-promo__form a {
  display: none; }

@media (max-width: 1200px) {
  .section-promo__subtitle {
    font-size: 18px; }
  .section-promo__title {
    font-size: 32px; }
  .section-promo__title span {
    font-size: 50px; }
  .promo-utp__item {
    flex-basis: 22%; } }

@media (max-width: 992px) {
  .section-promo__wrapper {
    flex-direction: column; }
  .promo-utp__item {
    flex-basis: 25%; }
  .promo-utp__text {
    padding: 0px; }
  .section-promo__content {
    width: 100%;
    margin-top: 30px; }
  .section-promo__form {
    width: 100%;
    margin-top: 10px; }
  .section-promo__form .c-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .section-promo__form .c-form .c-form__content-top {
    width: 100%;
    padding: 15px 10px; }
  .section-promo__form .c-form .c-form__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px 10px 5px 10px; }
  .section-promo__form .c-form .c-form__content .c-form__item {
    width: 32%; }
  .section-promo__form .c-form .c-form__content .c-form__item .c-form__label {
    margin-bottom: 0px; }
  .section-promo__form .c-form .c-form__content .c-form__item .c-form__btn {
    height: 46px;
    line-height: 15px;
    font-weight: normal;
    padding: 10px 5px;
    font-size: 15px; }
  .section-promo__form .c-form .c-form__conf {
    width: 100%;
    justify-content: center; } }

@media (max-width: 768px) {
  .section-promo__title {
    font-size: 28px; }
  .section-promo__title span {
    font-size: 45px; }
  .section-promo__desc {
    width: 100%;
    font-size: 25px; }
  .promo-utp__wrapper {
    justify-content: space-between; }
  .promo-utp__item {
    flex-basis: 47%;
    align-items: center;
    flex-direction: row;
    margin-bottom: 10px; }
  .promo-utp__icon {
    margin-right: 5px;
    margin-bottom: 0px; }
  .promo-utp__text {
    text-align: left;
    font-size: 16px;
    line-height: 1.2; }
  .section-promo .section-promo__wrap-form {
    display: none; }
  .section-promo__form a {
    display: block;
    text-align: center;
    background-color: #0CB121;
    text-transform: uppercase;
    padding: 15px 10px;
    font-size: 14px;
    line-height: 1.2; }
  .section-promo__form a:hover,
  .section-promo__form a:focus {
    text-decoration: none;
    background-color: #FFF;
    color: #0CB121;
    outline: none; } }

@media (max-width: 576px) {
  .section-promo__subtitle {
    font-size: 14px; }
  .section-promo__title {
    font-size: 20px;
    line-height: 1.5; }
  .section-promo__title span {
    font-size: 35px;
    letter-spacing: normal; }
  .section-promo__desc {
    width: 100%;
    font-size: 20px; }
  .promo-utp__item {
    flex-basis: 49%; }
  .promo-utp__text {
    font-size: 14px; } }

.section-advantages {
  position: relative;
  z-index: 2; }

.advantages__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 50px; }

.advantages__item {
  width: 24%;
  background-image: url("./../img/advantages-bg.jpg");
  background-size: cover;
  background-color: #3CA3E2;
  padding: 10px;
  margin-bottom: 1.33%; }

.advantages__content {
  border: 1px solid #FFFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; }

.advantages__icon {
  margin-bottom: 20px;
  height: 80px;
  display: flex;
  align-items: flex-start; }

.advantages__text {
  font-size: 18px;
  text-align: center;
  color: #FFF;
  font-weight: 500; }

@media (max-width: 1024px) {
  .advantages__text {
    font-weight: normal;
    font-size: 16px; } }

@media (max-width: 992px) {
  .advantages__icon {
    height: 50px;
    margin-bottom: 10px; }
  .advantages__icon svg {
    height: 50px;
    width: auto; }
  .advantages__content {
    padding: 10px; }
  .advantages__text {
    font-size: 14px;
    line-height: 1.3; } }

@media (max-width: 768px) {
  .advantages__wrapper {
    justify-content: center; }
  .advantages__item {
    width: 32%;
    margin-right: 1.33%; } }

@media (max-width: 576px) {
  .advantages__wrapper {
    justify-content: space-between; }
  .advantages__item {
    width: 49%;
    margin-right: 0; } }

.section-application {
  position: relative;
  padding-top: 130px; }

.section-application * {
  z-index: 9;
  position: relative; }

.section-application::before {
  content: '';
  position: absolute;
  bottom: 0px;
  z-index: 1;
  background-image: url("../img/bg-section-advantages.png");
  background-size: cover;
  height: 40%;
  width: 100%; }

.application__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.application__item {
  flex-basis: 49%;
  margin-bottom: 2%;
  position: relative; }

.application__link {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10; }

.application__images {
  height: 190px; }

.application__images img {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.application__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #1E7FFF;
  padding: 0px 10px;
  margin-top: -5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #FFFFFF; }

.application__icon {
  font-size: 33px;
  font-weight: 300; }

.application__link:hover ~ .application__content,
.application__link:focus ~ .application__content {
  background-color: #AD0600; }

@media (max-width: 576px) {
  .application__item {
    flex-basis: 100%; }
  .application__images {
    height: 120px; }
  .application__content {
    height: 45px;
    font-weight: normal;
    font-size: 14px; } }

.c-footer {
  background-color: #292929;
  padding: 20px 0px; }

.c-footer__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }

.footer__logo {
  width: 400px;
  color: #C4C4C4;
  display: flex;
  align-items: center;
  font-size: 12px; }

.footer__logo picture {
  padding-right: 5px;
  border-right: 1px solid #C4C4C4;
  width: 210px; }

.footer__logo span {
  padding-left: 5px;
  line-height: 1.2;
  flex: 1; }

.footer__contact {
  margin-left: auto;
  display: flex;
  align-items: center; }

.footer__tel {
  color: #FFF;
  font-weight: bold;
  font-size: 20px;
  margin-right: 10px; }

.footer__tel:hover,
.footer__tel:focus {
  text-decoration: none;
  color: #0CB121; }

.footer__btn {
  background-color: #fff;
  color: #0CB121;
  padding: 10px 15px;
  text-transform: uppercase; }

.footer__btn:hover,
.footer__btn:focus {
  text-decoration: none;
  color: #fff;
  background-color: #0CB121; }

.btn-scroll__link {
  width: 45px;
  height: 45px;
  border: 1px solid #959595;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer; }

.privacy-policy__link {
  color: #FFF;
  font-size: 14px;
  width: 50%;
  margin-top: 10px; }

.wbooster__link {
  margin-left: auto;
  margin-top: 10px; }

.wbooster {
  display: flex;
  align-items: center;
  color: #FFF;
  font-size: 12px; }

.wbooster__text {
  margin-left: 5px;
  padding-left: 5px;
  border-left: 1px solid #f7f7f7; }

#privacy-policy {
  max-width: 70%;
  padding: 20px; }

#privacy-policy p {
  line-height: 1.2; }

#privacy-policy p:not(:last-child) {
  margin-bottom: 8px; }

@media (max-width: 992px) {
  .footer__logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; }
  .footer__logo span {
    width: 170px;
    flex: initial; }
  .footer__contact {
    width: 100%;
    justify-content: center; }
  .btn-scroll__link {
    display: none; } }

@media (max-width: 576px) {
  .privacy-policy__link {
    width: 100%;
    text-align: center; }
  .footer__contact {
    flex-direction: column; }
  .wbooster__link {
    margin: 10px auto; } }

.banner__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.banner__logo {
  margin-left: 70px;
  width: 35%; }

.banner__images {
  width: 40%;
  margin-right: 70px; }

.c-form__gorizont {
  width: 100%;
  margin-top: -85px;
  padding: 10px;
  background-color: #FFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 30px; }

.c-form__gorizont .c-form {
  border: 1px solid #D0D0D0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px; }

.c-form__gorizont .c-form__content-top {
  background-color: transparent;
  padding: 0px;
  margin-bottom: 10px; }

.c-form__gorizont .c-form__title {
  color: #333;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0.02em; }

.c-form__gorizont .c-form__title span {
  color: #0CB121;
  display: block; }

.c-form__gorizont .c-form__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px; }

.c-form__gorizont .c-form__item {
  width: 32%;
  margin-bottom: 0px;
  height: 70px;
  display: flex;
  align-items: flex-end; }

.c-form__gorizont .c-form__label {
  margin-bottom: 0px; }

.c-form__gorizont .c-form__conf {
  justify-content: center;
  margin-top: 10px;
  padding: 0px; }

.c-form__gorizont .c-form__btn {
  background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%);
  border-radius: 3px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 992px) {
  .c-form__gorizont .c-form__title {
    font-size: 24px; }
  .c-form__gorizont .c-form__btn {
    font-size: 14px;
    font-weight: normal; } }

@media (max-width: 768px) {
  .c-form__gorizont .c-form__title {
    font-size: 20px; }
  .c-form__gorizont .c-form__content {
    flex-direction: column; }
  .c-form__gorizont .c-form__item {
    width: 100%; }
  .c-form__gorizont .c-form__btn {
    text-transform: uppercase; } }

/**/
.product__section {
  background-color: #F6F6F6; }

.product__item {
  display: inline-flex;
  margin-bottom: 20px; }

.product__images {
  width: 30%;
  position: relative; }

.product__collections {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px; }

.product__img img {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%; }

.product__content {
  flex: 1;
  background-color: #FFF;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.product__top {
  display: flex; }

.product__text {
  width: 60%; }

.product__colors {
  margin-left: auto;
  width: 35%; }

.product__name {
  font-weight: bold;
  font-size: 22px;
  color: #333333;
  text-transform: uppercase; }

.product__decsription {
  font-size: 16px;
  line-height: 1.3;
  color: #767676; }

.product-color {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.product__colors__title {
  font-weight: 600;
  font-size: 16px;
  color: #212529;
  margin-bottom: 20px; }

.product-color__item {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #FFF;
  margin-bottom: 5px; }

.product-color__item.color-active {
  border-color: #00D51B; }

.product-color__item:not(:last-child) {
  margin-right: 5px; }

.product__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.product__bottom a {
  width: 32%; }

.product__btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 15px 10px;
  border-radius: 3px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center; }

.product__btn:hover,
.product__btn:focus {
  color: #FFF;
  text-decoration: none; }

.product__btn-grey {
  background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%); }

.product__btn-grey:hover,
.product__btn-grey:focus {
  background: #00D51B; }

.product__btn-blue {
  background: linear-gradient(180deg, #38A6EB 0%, #1483C8 100%); }

.product__btn-blue:hover,
.product__btn-blue:focus {
  background: #38A6EB; }

@media (max-width: 992px) {
  .product__images {
    width: 48%; }
  .product__content {
    padding: 15px; }
  .product__top {
    flex-direction: column; }
  .product__colors {
    width: 100%; }
  .product__name {
    font-size: 18px; }
  .product__text {
    margin-right: 0px;
    width: 100%; }
  .product__decsription {
    font-size: 14px;
    margin-bottom: 10px; }
  .product__colors {
    margin-bottom: 10px; }
  .product__colors__title {
    margin-bottom: 10px; }
  .product__bottom {
    flex-wrap: wrap; }
  .product__bottom a {
    width: 100%;
    margin-bottom: 10px;
    font-weight: normal;
    padding: 10px;
    height: auto; } }

@media (max-width: 768px) {
  .product__item {
    flex-direction: column; }
  .product__images {
    width: 100%;
    height: 250px; } }

/**/
.cooperation__section {
  background: linear-gradient(180deg, #F6F6F6 75.15%, rgba(246, 246, 246, 0) 108.9%); }

.mobile-cooperation.swiper-container {
  overflow: inherit; }

.cooperation__wrapper {
  display: flex;
  justify-content: space-between; }

.cooperation__item {
  width: 32%;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden; }

.cooperation__top {
  height: 250px; }

.cooperation-images img {
  object-fit: cover;
  height: 100%;
  width: 100%; }

.cooperation__content {
  padding: 20px;
  min-height: 270px;
  display: flex;
  flex-direction: column; }

.cooperation__content .title {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.3;
  color: #333333;
  margin-bottom: 20px; }

ul.list-custom {
  list-style: none;
  padding-left: 30px;
  margin: 0px; }

ul.list-custom li {
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.2; }

ul.list-custom li::after {
  content: '';
  background-image: url(../img/sprite.svg#marker-ul);
  height: 16px;
  width: 16px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: -22px;
  top: 0; }

.cooperation__btn {
  margin-top: auto; }

.cooperation__btn a {
  color: #FFF;
  padding: 10px;
  display: block;
  text-align: center;
  background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%);
  border-radius: 3px;
  text-transform: uppercase; }

.cooperation__btn a:hover,
.cooperation__btn a:focus {
  background: #00D51B;
  text-decoration: none; }

@media (max-width: 992px) {
  .cooperation__wrapper {
    flex-wrap: wrap; }
  .cooperation__item {
    width: 100%;
    display: flex;
    margin-bottom: 10px; }
  .cooperation__top {
    width: 35%;
    height: auto; }
  .cooperation__content {
    flex: 1;
    padding: 10px 20px;
    min-height: auto; }
  .cooperation__content .title {
    margin-bottom: 10px; }
  .mobile-cooperation {
    height: 500px; } }

@media (max-width: 768px) {
  .mobile-cooperation.swiper-container {
    overflow: hidden; }
  .cooperation__wrapper {
    flex-wrap: inherit; }
  .cooperation__item {
    flex-direction: column;
    margin-bottom: 0px; }
  .cooperation__top {
    width: 100%;
    height: 200px; }
  .cooperation-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
  .cooperation-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px; }
  .cooperation-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #00D51B; }
  .cooperation-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-right: 5px; } }

/**/
.section-advantages-compani {
  background-image: linear-gradient(180deg, #FFFFFF 15.63%, rgba(255, 255, 255, 0) 66.75%), url("../img/s-advantages-bg.jpg");
  padding-top: 90px;
  background-size: cover; }

.advantages-compani__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 55%; }

.advantages-compani__item {
  width: 48%;
  display: flex;
  align-items: center;
  height: 120px;
  margin-bottom: 10px; }

.advantages-compani__icon {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1483C8;
  height: 100%; }

.advantages-compani__text {
  padding: 10px;
  flex: 1;
  height: 100%;
  line-height: 1.5;
  color: #333;
  background-color: #FFF;
  display: flex;
  align-items: center;
  font-weight: 500; }

@media (max-width: 992px) {
  .advantages-compani__wrapper {
    width: 100%; }
  .advantages-compani__item {
    height: 80px; }
  .advantages-compani__text {
    font-weight: normal; } }

@media (max-width: 576px) {
  .advantages-compani__icon {
    width: 60px; }
  .advantages-compani__icon svg {
    width: 40px;
    height: auto; }
  .advantages-compani__text {
    font-size: 14px; } }

@media (max-width: 400px) {
  .advantages-compani__item {
    width: 100%; } }

/**/
.about {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.about__left {
  width: 48%; }

.about__video {
  height: 300px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden; }

.video__img {
  position: relative; }

.video__img::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 112, 177, 0.8), rgba(0, 112, 177, 0.8)); }

.video__img img {
  object-fit: cover;
  height: 100%;
  width: 100%; }

.video__control {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center; }

.video__icon {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%; }

.video__text {
  font-size: 20px;
  color: #FFF;
  margin-top: 20px;
  font-weight: 500; }

.video__link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3; }

#video-modal {
  width: 65%;
  padding: 0px;
  background: transparent; }

#video-modal .fancybox-button svg path {
  fill: #FFF; }

@media (max-width: 768px) {
  #video-modal {
    width: 98%; } }

.sertificates__wrapper {
  display: flex;
  flex-wrap: wrap; }

.sertificates__item {
  width: 23%;
  position: relative; }

.sertificates__item:not(:last-child) {
  margin-right: 20px; }

.sertificates__link {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 4; }

.sertificates__img {
  position: relative; }

.sertificates__item:hover .sertificates__img::after,
.sertificates__item:hover .sertificates__img::before {
  content: '';
  position: absolute; }

.sertificates__item:hover .sertificates__img::after {
  background: linear-gradient(0deg, rgba(0, 112, 177, 0.8), rgba(0, 112, 177, 0.8));
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 3; }

.sertificates__item:hover .sertificates__img::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(../img/sprite.svg#eye);
  background-position: center;
  background-repeat: no-repeat;
  z-index: 4;
  width: 50px;
  height: 50px; }

.about__rigth {
  width: 48%; }

.about__rigth .title {
  font-weight: bold;
  color: #0CB121; }

.about__rigth ul.list-custom {
  margin-bottom: 40px; }

@media (max-width: 992px) {
  .about {
    flex-direction: column; }
  .about__left {
    width: 100%;
    order: 2; }
  .about__rigth {
    width: 100%;
    order: 1; }
  .about__video {
    height: 250px; }
  .video__img {
    height: 100%; }
  .video__text {
    text-align: center;
    font-weight: normal;
    font-size: 16px; }
  .sertificates__item {
    width: 15%; } }

@media (max-width: 576px) {
  .sertificates__item {
    width: 25%; } }

.section-c-interesting {
  background: linear-gradient(180deg, #F6F6F6 2.76%, rgba(246, 246, 246, 0) 38.4%); }

.section__title2 {
  color: #333333;
  font-weight: bold;
  font-size: 40px;
  text-transform: uppercase; }

.section__subtitle {
  font-size: 20px;
  font-weight: 500;
  color: #0CB121;
  margin-bottom: 20px; }

.c-interesting__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  border-radius: 5px; }

.c-interesting__inner::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 110%;
  height: 100%;
  background-color: #F6F6F6;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  z-index: auto; }

.c-interesting__form-group {
  width: 48%;
  z-index: 2;
  font-size: 18px;
  margin-bottom: 10px; }

@media (max-width: 992px) {
  .section__title2 {
    font-size: 20px; }
  .section__subtitle {
    font-size: 16px; }
  .c-interesting__inner {
    padding: 0px; }
  .c-interesting__form-group {
    width: 100%;
    margin-bottom: 5px; }
  .c-interesting__inner::before {
    display: none; } }

.layers-wrapper {
  display: flex; }

.layers-content {
  flex-basis: 75%;
  position: relative; }

.layers-text-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-left: 30px; }

.layers-text-block .title {
  max-width: 45%;
  color: #1483C8;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px; }

.layers-img {
  position: absolute;
  top: -19%;
  left: 70%;
  z-index: 2;
  width: 510px;
  height: 388px; }

.layers-img::before {
  content: '';
  display: block;
  height: 490px;
  width: 490px;
  background-image: url("../img/sprite.svg#layers__before");
  z-index: 1; }

.layers-img::after {
  content: '';
  display: block;
  height: 200px;
  width: 200px;
  background-image: url("../img/sprite.svg#layers__after");
  position: absolute;
  top: -30px;
  right: 0px; }

.layers-img img {
  position: absolute;
  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10; }

.layers-text {
  color: #333333;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  position: relative;
  display: inline-block;
  border-bottom: 2px solid #0CB121; }

.layers-text::after {
  content: '';
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #0070B1;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%); }

.layers-text .mobile {
  display: none; }

.layers-hr {
  margin-bottom: 15px;
  position: relative;
  background: #C4C4C4;
  height: 1px;
  width: 90%;
  z-index: 3; }

.layers-hr::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0px;
  height: 10px;
  width: 10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transform: translateY(-50%); }

.layers-legend-mobile {
  display: none; }

.first-hr {
  width: 95%; }

.second-hr {
  width: 86%; }

@media (max-width: 1200px) {
  .layers-text-block .title {
    max-width: 70%; } }

@media (max-width: 992px) {
  .structure__section {
    padding-bottom: 40px; }
  .layers-text-block .title {
    max-width: 100%;
    font-weight: 500;
    line-height: 1.2; }
  .layers-img::after {
    width: 150px;
    top: -15px; }
  .layers-img::before {
    height: 350px;
    width: 350px; }
  .layers-img {
    top: -16%;
    width: 400px;
    height: 400px; }
  .layers-img img {
    top: 46%;
    left: 50%; }
  .layers-text {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: normal; }
  .layers-hr {
    margin-bottom: 10px; }
  .second-hr {
    width: 90%; } }

@media (max-width: 768px) {
  .layers-text .desk {
    display: none; }
  .layers-text .mobile {
    border: 1px solid #FFF;
    background: linear-gradient(180deg, #0FDE29 19.23%, #0CB121 100%);
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #FFF; }
  .layers-hr {
    display: none; }
  .layers-text {
    display: block;
    border: 0px; }
  .layers-text-block {
    position: absolute;
    top: 0px;
    background-color: transparent;
    z-index: 10;
    padding: 0px;
    box-shadow: none;
    width: 100%;
    height: 100%; }
  .layers-img {
    position: relative;
    width: 100%;
    left: 0px;
    top: 0px; }
  .layers-text-block .title {
    display: none; }
  .layers-content {
    flex-basis: 100%; }
  .layers-img::before {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%; }
  .layers-text::after {
    display: none; }
  .layers-wrapper {
    flex-wrap: wrap; }
  .layers-legend-mobile {
    display: block; }
  .layers-text-block .layers-text {
    position: absolute; }
  .layers-text-block .text-1 {
    top: 38%;
    left: 54%; }
  .layers-text-block .text-2 {
    top: 47%;
    left: 47%; }
  .layers-text-block .text-3 {
    top: 56%;
    left: 40%; }
  .layers-text-block .text-4 {
    top: 65%;
    left: 33%; }
  .layers-text-block .text-5 {
    top: 72%;
    left: 26%; }
  .layers-text-block .text-6 {
    top: 81%;
    left: 21%; }
  .layers-img img {
    width: 450px;
    height: 350px; }
  .layers-legend-mobile .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-transform: uppercase;
    color: #1483C8; }
  .layers-legend-mobile .layers-text {
    display: flex;
    align-items: flex-start; }
  .layers-legend-mobile .layers-text .mobile-number {
    font-size: 24px;
    font-weight: bold;
    color: #0CB121;
    margin-right: 5px; }
  .layers-legend-mobile .layers-text .mobile-text {
    padding-top: 3px;
    font-size: 14px;
    border-bottom: 1px solid #0CB121; } }

.gallery-houses__section {
  background-image: url("../img/gallery-houses.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.gallery-houses__section .section__title {
  color: #FFF; }

.mySwiper2 {
  height: 500px; }

.mySwiper2 picture img {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.mySwiper {
  margin-top: 10px;
  height: 100px; }

@media (max-width: 992px) {
  .mySwiper2 {
    height: 300px; } }

.mySwiper picture img {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.mySwiper .swiper-slide-thumb-active:before {
  content: '';
  position: absolute;
  background: linear-gradient(0deg, rgba(20, 131, 200, 0.5), rgba(20, 131, 200, 0.5));
  width: 100%;
  height: 100%; }
