
.header-mobile {
  padding: 10px 20px;
  box-shadow: 0 0 20px 0 #bcbfc4;
  background-color: #FFF;
}
.block-fixed {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 400;
}

.header-mobile__contact-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-mobile__burger-menu {
    cursor: pointer;
    position: relative;
    width: 35px;
    height: 25px;
}

.header-mobile__burger--global {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    border-top: 2px solid #1483C8;
    width: 100%;
    transition: 0.55s;
}

.header-mobile__burger-top {
    top: 0;
}
.header-mobile__burger-middle {
    top: 50%;
    transform: translateY(-50%);
}
.header-mobile__burger-bottom {
    bottom: 0px;
}
.header-mobile__burger-menu.active .header-mobile__burger--global{
  backface-visibility: hidden;
  transition: 0.2s 0.2s;
}
.header-mobile__burger-menu.active .header-mobile__burger-top {
  top: 15px;
  transform: rotate(50deg);
}

.header-mobile__burger-menu.active .header-mobile__burger-bottom {
  top: 15px;
  bottom: auto;
  transform: rotate(-410deg);
}

.header-mobile__burger-menu.active .header-mobile__burger-middle {
  opacity: 0;
}



  

.header-mobile__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-mobile__logo{
  flex: 1;
  max-width: 30%;
}


.header-mobile__inner-contact{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100px;
}
.header-mobile__inner-phone{
  flex-basis: 30px;
  position: relative
}
.header-mobile__phone {
  display: inline-block;
  font-size: 1px;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  opacity: 0;
}

.header-mobile__inner-phone svg{
  width: 30px;
  height: 30px;
}

.header-mobile__mail{
  flex-basis: 30px;
} 

.header-mobile__mail svg{
  width: 30px;
  height: 30px;
}
  
  
  .header-mobile__link-img {
    width: 2.2rem
  }
  
  .header-mobile__list {
    list-style-type: none;
    margin: 2rem 0 0
  }
  
  .header-mobile__list-item:not(:first-child) {
    margin-top: 1rem
  }
  
  .header-mobile__link {
    text-decoration: none;
    color: #2e3033
  }
  
  .header-mobile__link:active,
  .header-mobile__link:focus,
  .header-mobile__link:hover {
    text-decoration: none;
    color: #626366
  }
  
  .header-mobile__burger {
    cursor: pointer
  }

.header-mobile--mobile{
  padding-top: 20px;
}