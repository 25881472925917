.header.is-clone {
    position: fixed;
    top: -10rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 99;
    transition: .5s;
    padding: 10px 0;
    background-color: #FFF;
    box-shadow: 0 4px 16px 0 rgba(154, 147, 140, 0.5);
}

.header.is-fixed {
    top: 0 !important
}

.header__contact--clone {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.header__menu{
    width: 50%;
}
.header__list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__link{
    border-bottom: 1px dashed #FFF;
}

.header__link:hover,
.header__link:focus{
    color: #0CB121;
    text-decoration: none;
    border-color:#0CB121;
}


.header.is-clone .header__phone {
    color: #333;
}

.header.is-clone .header__phone:hover,
.header.is-clone .header__phone:focus{
    color: #0CB121;
}

.header__btn--clone {
    cursor: pointer;
    
    border: solid 1px $accent-color;
    color: $accent-color;
    background-color: transparent;
    text-align: center;
    padding: 5px 10px;
    text-decoration: none;
    transition: all .2s;
}

.header__btn--clone:hover,
.header__btn--clone:focus{
    background-color: $accent-color;
    color: #FFF;
    text-decoration: none;
}


@media (max-width: 1024px) {
    .header__btn--clone {
        padding: 5px;
        font-size: 14px;
    }
}