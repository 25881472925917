@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap');

body{
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
}

$accent-color: #0F1A34 !global; 
$accent-color2: #76d700 !global; 


@import "./components/form";
@import "./components/header-mobile";
@import "./components/header-clone";
@import "./components/header/header-no-menu";

@import "./components/first-screen/block-3";
@import "./components/advantages/advantages-3";
@import "./components/application/application-1";

@import "./components/footer/footer-4";




.banner__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.banner__logo{
    margin-left: 70px;
    width: 35%;
}

.banner__images{
    width: 40%;
    margin-right: 70px;
}

.c-form__gorizont{
    width: 100%;
    margin-top: -85px;
    padding: 10px;
    background-color: #FFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 30px;
}
.c-form__gorizont .c-form{
    border: 1px solid #D0D0D0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px;
}

.c-form__gorizont .c-form__content-top{
    background-color: transparent;
    padding: 0px;
    margin-bottom: 10px;
}
.c-form__gorizont .c-form__title{
    color: #333;
    font-weight: bold;
    font-size: 32px;
    letter-spacing: 0.02em;
}

.c-form__gorizont .c-form__title span{
    color: #0CB121;
    display: block;
}

.c-form__gorizont .c-form__content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
}

.c-form__gorizont .c-form__item{
    width: 32%;
    margin-bottom: 0px;
    height: 70px;
    display: flex;
    align-items: flex-end;
}

.c-form__gorizont .c-form__label{
    margin-bottom: 0px;
}
.c-form__gorizont .c-form__conf{
    justify-content: center;
    margin-top: 10px;
    padding: 0px;
}

.c-form__gorizont .c-form__btn{
    background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%);
    border-radius: 3px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media(max-width: 992px) {
    .c-form__gorizont .c-form__title{
        font-size: 24px;
    }

    .c-form__gorizont .c-form__btn{
        font-size: 14px;
        font-weight: normal;
    }
}

@media(max-width: 768px) {
    .c-form__gorizont .c-form__title{
        font-size: 20px;
    }
    .c-form__gorizont .c-form__content{
        flex-direction: column;
    }
    .c-form__gorizont .c-form__item{
        width: 100%;
    }
    .c-form__gorizont .c-form__btn{
        text-transform: uppercase;
    }
}

/**/

.product__section{
    background-color: #F6F6F6;
}
.product__item{
    display: inline-flex;
    margin-bottom: 20px;
}

.product__images{
    width: 30%;
    position: relative;
}

.product__collections{
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
    width: 60px;
    height: 60px;
}

.product__img img{
    display: block;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.product__content {
    flex: 1;
    background-color: #FFF;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.product__top {
    display: flex;
}

.product__text {
    width: 60%;
}

.product__colors{
    margin-left: auto;
    width: 35%;
}

.product__name{
    font-weight: bold;
    font-size: 22px;
    color: #333333;
    text-transform: uppercase;
}

.product__decsription{
    font-size: 16px;
    line-height: 1.3;
    color: #767676;
}

.product-color{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.product__colors__title{
    font-weight: 600;
    font-size: 16px;
    color: #212529;
    margin-bottom: 20px;
}

.product-color__item{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #FFF;
    margin-bottom: 5px;
}
.product-color__item.color-active{
    border-color: #00D51B;
}
.product-color__item:not(:last-child){
    margin-right: 5px;
}


.product__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product__bottom a{
    width: 32%;
}
.product__btn{
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 15px 10px;
    border-radius: 3px;

    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product__btn:hover,
.product__btn:focus{
    color: #FFF;
    text-decoration: none;
}

.product__btn-grey{
    background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%);
}

.product__btn-grey:hover,
.product__btn-grey:focus{
    background: #00D51B;
}

.product__btn-blue{
    background: linear-gradient(180deg, #38A6EB 0%, #1483C8 100%);
}

.product__btn-blue:hover,
.product__btn-blue:focus{
    background: #38A6EB;
}


@media(max-width: 992px) {
    .product__images{
        width: 48%;
    }
    .product__content{
        padding: 15px;
    }
    .product__top{
        flex-direction: column;
    }
    .product__colors{
        width: 100%;
    }
    .product__name{
        font-size: 18px;
    }
    .product__text{
        margin-right: 0px;
        width: 100%;
    }
    .product__decsription{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .product__colors{
        margin-bottom: 10px;
    }
    .product__colors__title{
        margin-bottom: 10px;
    }

    .product__bottom{
        flex-wrap: wrap;
    }
    .product__bottom a{
        width: 100%;
        margin-bottom: 10px;
        font-weight: normal;
        padding: 10px;
        height: auto;
    }
}

@media(max-width: 768px) {
    .product__item{
        flex-direction: column;
    }
    .product__images{
        width: 100%;
        height: 250px;
    }
}


/**/
.cooperation__section {
    background: linear-gradient(180deg, #F6F6F6 75.15%, rgba(246, 246, 246, 0) 108.9%);
}
.mobile-cooperation.swiper-container{
    overflow: inherit
}
.cooperation__wrapper {
    display: flex;
    justify-content: space-between;
}
.cooperation__item{
    width: 32%;
    border-radius: 5px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.cooperation__top{
    height: 250px;
}

.cooperation-images img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.cooperation__content{
    padding: 20px;
    min-height: 270px;
    display: flex;
    flex-direction: column;

}

.cooperation__content .title{
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    color: #333333;
    margin-bottom: 20px;
}


ul.list-custom{
    list-style: none;
    padding-left: 30px;
    margin: 0px;
}

ul.list-custom li{
    position: relative;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.2;
}

ul.list-custom li::after{
    content: '';
    background-image: url(../img/sprite.svg#marker-ul);
    height: 16px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -22px;
    top: 0;
}
.cooperation__btn{
    margin-top: auto;
}
.cooperation__btn a{
    color: #FFF;
    padding: 10px;
    display: block;
    text-align: center;
    background: linear-gradient(180deg, #00D51B 0%, #0CB121 100%);
    border-radius: 3px;
    text-transform: uppercase;
}

.cooperation__btn a:hover,
.cooperation__btn a:focus{
    background: #00D51B;
    text-decoration: none;
}

@media(max-width: 992px){
    .cooperation__wrapper{
        flex-wrap: wrap;
    }
    .cooperation__item{
        width: 100%;
        display: flex;
        margin-bottom: 10px;
    }
    .cooperation__top{
        width: 35%;
        height: auto;
    }
    .cooperation__content{
        flex: 1;
        padding: 10px 20px;
        min-height: auto;
    }
    .cooperation__content .title{
        margin-bottom: 10px;
    }
    .mobile-cooperation{
        height: 500px;
    }
}

@media(max-width: 768px){
    .mobile-cooperation.swiper-container{
        overflow: hidden;
    }
    .cooperation__wrapper{
        flex-wrap: inherit;
    }
    .cooperation__item{
        flex-direction: column;
        margin-bottom: 0px
    }
    .cooperation__top{
        width: 100%;
        height: 200px;
    }

    .cooperation-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    .cooperation-pagination .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
    }
    .cooperation-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active{
        background-color: #00D51B;
    }
    .cooperation-pagination .swiper-pagination-bullet:not(:last-child){
        margin-right: 5px;
    }
}


/**/

.section-advantages-compani{
    background-image: linear-gradient(180deg, #FFFFFF 15.63%, rgba(255, 255, 255, 0) 66.75%), url('../img/s-advantages-bg.jpg');
    padding-top: 90px;
    background-size: cover;
}


.advantages-compani__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 55%;
}

.advantages-compani__item {
    width: 48%;
    display: flex;
    align-items: center;
    height: 120px;
    margin-bottom: 10px;
}

.advantages-compani__icon {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1483C8;
    height: 100%;
}

.advantages-compani__text {
    padding: 10px;
    flex: 1;
    height: 100%;
    line-height: 1.5;
    color: #333;
    background-color: #FFF;
    display: flex;
    align-items: center;
    font-weight: 500;
}

@media(max-width: 992px){
    .advantages-compani__wrapper{
        width: 100%;
    }
    .advantages-compani__item{
        height: 80px;
    }
    .advantages-compani__text{
        font-weight: normal;
    }
}

@media(max-width: 576px){
    .advantages-compani__icon{
        width: 60px;
    }
    .advantages-compani__icon svg{
        width: 40px;
        height: auto;
    }
    .advantages-compani__text{
        font-size: 14px;
    }
}

@media(max-width: 400px){
    .advantages-compani__item{
        width: 100%;
    }
}

/**/
.about{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.about__left{
    width: 48%;
}


.about__video{
    height: 300px;
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}
.video__img{
    position: relative;
}
.video__img::before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 112, 177, 0.8), rgba(0, 112, 177, 0.8));
}

.video__img img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.video__control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.video__icon {
    height: 100px;
    width: 100px;
    display: flex;  
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
}

.video__text{
    font-size: 20px;
    color: #FFF;
    margin-top: 20px;
    font-weight: 500;
}

.video__link{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
}

#video-modal{
    width: 65%;
    padding: 0px;
    background: transparent;
}
#video-modal .fancybox-button svg path{
    fill: #FFF;
}

@media (max-width: 768px) {
    #video-modal{
        width: 98%;
    }  
}

.sertificates__wrapper{
    display: flex;
    flex-wrap: wrap;
}

.sertificates__item{
    width: 23%;
    position: relative;
}

.sertificates__item:not(:last-child){
    margin-right: 20px;
}

.sertificates__link{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 4;
}
.sertificates__img{
    position: relative;
}

.sertificates__item:hover .sertificates__img::after,
.sertificates__item:hover .sertificates__img::before{
    content: '';
    position: absolute;
}

.sertificates__item:hover .sertificates__img::after{
    background: linear-gradient(0deg, rgba(0, 112, 177, 0.8), rgba(0, 112, 177, 0.8));
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.sertificates__item:hover .sertificates__img::before{
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: url(../img/sprite.svg#eye);
    background-position: center;
    background-repeat: no-repeat;
    z-index: 4;
    width: 50px;
    height: 50px;
}

.about__rigth{
    width: 48%;
}


.about__rigth .title{
    font-weight: bold;
    color: #0CB121;
}

.about__rigth ul.list-custom{
    margin-bottom: 40px;
}

@media(max-width: 992px){
    .about{
        flex-direction: column;
    }
    .about__left{
        width: 100%;
        order: 2;
    }
    .about__rigth{
        width: 100%;
        order: 1;
    }

    .about__video{
        height: 250px;
    }
    .video__img{
        height: 100%;
    }
    .video__text{
        text-align: center;
        font-weight: normal;
        font-size: 16px;
    }

    .sertificates__item{
        width: 15%;
    }
}

@media (max-width: 576px) {
    .sertificates__item {
        width: 25%;
    }
}

.section-c-interesting{
    background: linear-gradient(180deg, #F6F6F6 2.76%, rgba(246, 246, 246, 0) 38.4%);
}
.section__title2{
    color: #333333;
    font-weight: bold;
    font-size: 40px;
    text-transform: uppercase;
}

.section__subtitle{
    font-size: 20px;
    font-weight: 500;
    color: #0CB121;
    margin-bottom: 20px;
}


.c-interesting__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    padding: 20px;
    border-radius: 5px;
}

.c-interesting__inner::before{
    content: '';

    position: absolute;
    top: 0px;
    left: 0px;
    width: 110%;
    height: 100%;
    background-color: #F6F6F6;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    z-index: auto;
}

.c-interesting__form-group{
    width: 48%;
    z-index: 2;
    font-size: 18px;
    margin-bottom: 10px;
}

@media(max-width: 992px){
    .section__title2{
        font-size: 20px;
    }
    .section__subtitle{
        font-size: 16px;
    }
    .c-interesting__inner{
        padding: 0px;
    }
    .c-interesting__form-group{
        width: 100%;
        margin-bottom: 5px;
    }
    .c-interesting__inner::before{
        display: none;
    }
}





.layers-wrapper {
    display: flex;
}


.layers-content {
    flex-basis: 75%;
    position: relative;
}

.layers-text-block {
    // margin-bottom: 50px;

    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    padding-left: 30px;


}

.layers-text-block .title{
    max-width: 45%;
    color: #1483C8;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.layers-img {
    position: absolute;
    top: -19%;
    left: 70%;
    z-index: 2;
    width: 510px;
    height: 388px;
}

.layers-img::before{
    content: '';
    display: block;
    height: 490px;
    width: 490px;
    // background-color: #0CB121;
    // border-radius: 50%;
    background-image: url('../img/sprite.svg#layers__before');
    z-index: 1;
    // border: 10px solid #FFF;
    // box-sizing: border-box;
    // box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.layers-img::after{
    content: '';
    display: block;
    height: 200px;
    width: 200px;
    background-image: url('../img/sprite.svg#layers__after');
    position: absolute;
    top: -30px;
    right: 0px
}

.layers-img img{
    position: absolute;
    top: 59%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.layers-text {
    color: #333333;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.02em;
    position: relative;
    display: inline-block;
    border-bottom: 2px solid #0CB121;
}

.layers-text::after{
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #0070B1;

    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
}

.layers-text .mobile{
    display: none;
}

.layers-hr {
    margin-bottom: 15px;
    position: relative;
    background: #C4C4C4;
    height: 1px;
    width: 90%;
    z-index: 3;
}

.layers-hr::before{
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    height: 10px;
    width: 10px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(-50%);
}
.layers-legend-mobile{
    display: none;  
}
.first-hr {
    width: 95%;
}
.second-hr {
    width: 86%;
}

@media(max-width: 1200px){
    .layers-text-block .title{
        max-width: 70%;
    }
}

@media(max-width: 992px){
    .structure__section{
        padding-bottom: 40px;
    }
    .layers-text-block .title{
        max-width: 100%;
        font-weight: 500;
        line-height: 1.2;
    }
    .layers-img::after{
        width: 150px;
        top: -15px;
    }
    .layers-img::before{
        height: 350px;
        width: 350px;
    }
    .layers-img{
        top: -16%;
        width: 400px;
        height: 400px;
    }
    .layers-img img{
        top: 46%;
        left: 50%;
    }
    .layers-text{
        font-size: 14px;
        line-height: 1.4;
        letter-spacing: normal
    }
    .layers-hr{
        margin-bottom: 10px;
    }
    .second-hr{
        width: 90%;
    }
}

@media(max-width: 768px){
    .layers-text .desk{
        display: none;
    }
    .layers-text .mobile{
        border: 1px solid #FFF;
        background: linear-gradient(180deg, #0FDE29 19.23%, #0CB121 100%);
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: #FFF;
    }
    .layers-hr{
        display: none;
    }
    .layers-text{
        display: block;
        border: 0px
    }
    .layers-text-block{
        position: absolute;
        top: 0px;
        background-color: transparent;
        z-index: 10;
        padding: 0px;
        box-shadow: none;
        width: 100%;
        height: 100%;
    }
    .layers-img{
        position: relative;
        width: 100%;
        left: 0px;
        top: 0px;
    }
    .layers-text-block .title{
        display: none;
    }
    .layers-content{
        flex-basis: 100%;
    }
    .layers-img::before{
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 100%;
    }

    .layers-text::after{
        display: none;
    }
    .layers-wrapper{
        flex-wrap: wrap;
    }
    .layers-legend-mobile{
        display: block;
    }

    .layers-text-block .layers-text{
        position: absolute;
    }

    .layers-text-block .text-1{
        top: 38%;
        left: 54%;  
    }
    .layers-text-block .text-2{
        top: 47%;
        left: 47%;
    }
    .layers-text-block .text-3{
        top: 56%;
        left: 40%;
    }
    .layers-text-block .text-4{
        top: 65%;
        left: 33%;
    }
    .layers-text-block .text-5{
        top: 72%;
        left: 26%;
    }
    .layers-text-block .text-6{
        top: 81%;
        left: 21%;
    }
    .layers-img img{
        width: 450px;
        height: 350px;
    }
    .layers-legend-mobile .title{
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        text-transform: uppercase;

        color: #1483C8;
    }
    .layers-legend-mobile .layers-text {
        display: flex;
        align-items: flex-start;
    }
    .layers-legend-mobile .layers-text .mobile-number {
        font-size: 24px;
        font-weight: bold;
        color: #0CB121;
        margin-right: 5px;
    }
    .layers-legend-mobile .layers-text .mobile-text {
        padding-top: 3px;
        font-size: 14px;
        border-bottom: 1px solid #0CB121;
    }
}



.gallery-houses__section{
    background-image: url('../img/gallery-houses.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.gallery-houses__section .section__title{
    color: #FFF;
}

.mySwiper2{
    height: 500px;
}



.mySwiper2 picture img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}


.mySwiper{
    margin-top: 10px;
    height: 100px;
}

@media(max-width: 992px){
    .mySwiper2{
        height: 300px;
    }
}
.mySwiper picture img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.mySwiper .swiper-slide-thumb-active:before{
    content: '';
    position: absolute;
    background: linear-gradient(0deg, rgba(20, 131, 200, 0.5), rgba(20, 131, 200, 0.5));
    width: 100%;
    height: 100%;
}
