.section-advantages{
    position: relative;
    z-index: 2;
}


.advantages__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
}

.advantages__item{
    width: 24%;
    background-image: url('./../img/advantages-bg.jpg');
    background-size: cover;
    background-color: #3CA3E2;
    padding: 10px;
    margin-bottom: 1.33%;
}

.advantages__content{
    border: 1px solid #FFFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.advantages__icon{
    margin-bottom: 20px;
    height: 80px;
    display: flex;
    align-items: flex-start;
}

.advantages__text{
    font-size: 18px;
    text-align: center;
    color: #FFF;
    font-weight: 500;
}


@media (max-width: 1024px) {
    .advantages__text{
        font-weight: normal;
        font-size: 16px;
    }
}
@media (max-width: 992px) {
    .advantages__icon{
        height: 50px;
        margin-bottom: 10px;
    }
    .advantages__icon svg{
        height: 50px;
        width: auto;
    }
    .advantages__content{
        padding: 10px;
    }
    .advantages__text{
        font-size: 14px;
        line-height: 1.3;
    }
}

@media (max-width: 768px) {
    .advantages__wrapper{
        justify-content: center;
    }
    .advantages__item{
        width: 32%;
        margin-right: 1.33%;
    }

}

@media (max-width: 576px) {
    .advantages__wrapper{
        justify-content: space-between;
    }
    .advantages__item{
        width: 49%;
        margin-right: 0;
    }
}