.section-promo {
    position: relative;
    background-size: cover;
    padding: 40px 0px;
    padding-top: 0px;
    color: #fff;
}

.section-promo::before{
    content: '';
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.section-promo__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

.section-promo__content{
    flex-basis: 70%;
    min-height: 150px;
}

.section-promo__title{
    font-size: 40px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    line-height: 1.2;
    text-shadow: 0px 4px 80px rgba(0, 0, 0, 0.8);
    letter-spacing: 0.06em;
}

.section-promo__title span{
    font-size: 65px;
    letter-spacing: 0.01em;
    display: block;
}

.section-promo__subtitle{
    font-size: 21px;
    text-transform: uppercase;
    color: #F2F3F5;
}

.section-promo__desc{
    width: 80%;
    margin-top: 10px;
    display: block;
    background-color: #1483C8;
    color: #FFF;
    padding: 10px 20px;
    line-height: 1;;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}


.promo-utp__wrapper{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.promo-utp__item{
    flex-basis: 19%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.promo-utp__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.promo-utp__icon svg {
    overflow: hidden;
    vertical-align: middle;
}

.promo-utp__text{
    flex: 1;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 0px 15px;
}


.section-promo__form{
    flex-basis: 30%;
}


.section-promo__form a{
    display: none;
}




@media (max-width: 1200px) {
    .section-promo__subtitle{
        font-size: 18px;
    }
    .section-promo__title{
        font-size: 32px;
    }
    .section-promo__title span{
        font-size: 50px;
    }
    .promo-utp__item{
        flex-basis: 22%;
    }
}   

@media (max-width: 992px) {
    .section-promo__wrapper{
        flex-direction: column;
    }  
    .promo-utp__item{
        flex-basis: 25%;
    }
    .promo-utp__text{
        padding: 0px;
    }
    .section-promo__content{
        width: 100%;
        margin-top: 30px;
    }
    .section-promo__form{
        width: 100%;
        margin-top: 10px;
    }

    .section-promo__form .c-form{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .section-promo__form .c-form .c-form__content-top{
        width: 100%;
        padding: 15px 10px;
    }

    .section-promo__form .c-form .c-form__content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
        padding: 15px 10px 5px 10px;
    }

    .section-promo__form .c-form .c-form__content .c-form__item{
        width: 32%;
    }
    .section-promo__form .c-form .c-form__content .c-form__item .c-form__label{
        margin-bottom: 0px;
    }

    .section-promo__form .c-form .c-form__content .c-form__item .c-form__btn{
        height: 46px;
        line-height: 15px;
        font-weight: normal;
        padding: 10px 5px;
        font-size: 15px;
    }

    .section-promo__form .c-form .c-form__conf{
        width: 100%;
        justify-content: center;
    }
}


@media (max-width: 768px) {
    .section-promo__title{
        font-size: 28px;
    }
    .section-promo__title span{
        font-size: 45px;
    }
    .section-promo__desc{
        width: 100%;
        font-size: 25px;
    }
    .promo-utp__wrapper{
        justify-content: space-between
    }
    .promo-utp__item{
        flex-basis: 47%;
        align-items: center;
        flex-direction: row;
        margin-bottom: 10px;
    }
    .promo-utp__icon{
        margin-right: 5px;
        margin-bottom: 0px;
    }
    .promo-utp__text{
        text-align: left;
        font-size: 16px;
        line-height: 1.2;
    }
    .section-promo .section-promo__wrap-form{
        display: none;
    }
    .section-promo__form a {
        display: block;
        text-align: center;
        background-color: #0CB121;
        text-transform: uppercase;
        padding: 15px 10px;
        font-size: 14px;
        line-height: 1.2;
    }

    .section-promo__form a:hover,
    .section-promo__form a:focus{
        text-decoration: none;
        background-color: #FFF;
        color: #0CB121;
        outline: none;
    }
}


@media (max-width: 576px) {
    .section-promo__subtitle{
        font-size: 14px;
    }
    .section-promo__title{
        font-size: 20px;
        line-height: 1.5;
    }
    .section-promo__title span{
        font-size: 35px;
        letter-spacing: normal;
    }
    .section-promo__desc{
        width: 100%;
        font-size: 20px;
    }
    .promo-utp__item{
        flex-basis: 49%;
    }
    .promo-utp__text{
        font-size: 14px;
    }
}

