
.c-form{
    background-color: #fff;
    width: 100%;
    border-radius: 2px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
}

.c-form__content-top{
    background: #0CB121;
    padding: 30px 15px;
}

.c-form__title{
    text-align: center;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
}

.c-form__content{
    padding: 20px 20px 0px 20px;
}

.c-form__item{
    width: 100%;
    margin-bottom: 20px;
}

.c-form__label{
    width: 100%;
    position: relative;
}

.c-form__input-title{
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    color: #333;
}

.input__tel, 
.input__text{
    width: 100%;
    padding: 10px;
    background: #F6F6F6;
    border: 1px solid #F6F6F6;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.input__tel.\--error,
.input__text.\--error {
    border-color: #f99;
}

.c-form__input-error {
    position: absolute;
    bottom: -22px;
    right: 0;
    line-height: 1;
    font-size: 12px;
    color: #f99;
}

.c-form__checkbox{
    display: flex;
    align-items: center;
    color: #333;
    font-size: 12px;
}

.c-form__checkbox input[type=checkbox]{
    margin-right: 4px;
}

.c-form__btn{
    background: #0CB121;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #FFF;
    padding: 10px;
    border: none;
    width: 100%;
    text-transform: uppercase;
}

.c-form__conf{
    padding: 5px 20px 20px 20px;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 10px;
    justify-content: center;
}

.c-form__conf--icon{
    margin-right: 5px;
}


.c-modal-form{
    padding: 0px;
    width: 100%;
    max-width: 333px;
}

// .c-modal-form .c-form{
//     max-width: 430px;
// }
.c-modal-form .fancybox-button svg{
    color: #FFF;
}


.formFooter .c-form__content-top{
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
}

.formFooter .c-form__content-top .c-form__title{
    color: #333;
    font-size: 28px;
    font-weight: 500;
    line-height: 1.3;
}

.formFooter .c-form__title span{
    text-transform: uppercase;
    color: #0CB121;
}



.c-modal {
    position: relative;
    z-index: 1000000;
}

.c-modal__container {
    position: fixed;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: 0;
    background: #0000005a;
    top: 0;
    touch-action: manipulation;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    width: 100%;
}

.c-modal__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 55rem;
    height: 100%;
    margin: auto;
    padding: 0 2rem;
}

.c-message__item {
    padding: 40px 80px 40px 40px;
    display: flex;
    //box-shadow: 0 2px 4px rgb(0 0 0 / 24%), 0 4px 16px rgb(0 0 0 / 16%);
    border-radius: 2px;
    background-color: #fff;
    position: relative;
}

.c-message__item--close {
    position: absolute;
    right: 0;
    top: 0;
    width: 3.2rem;
    height: 3.2rem;
    opacity: .3;
    cursor: pointer;
}

.c-message__wrap-img {
    margin-right: 35px;
    max-width: 120px;
}

.c-message__img {
    min-width: 100px;
}

.c-message__title {
    font-weight: 400;
    line-height: 25px;
    font-size: 16px;
    color: #515255;
}

.c-message__text {
    margin-top: 10px;
    font-weight: 400;
    line-height: 15px;
    font-size: 12px;
    color: #949699;
}

.c-message__msg {
    margin-top: 10px;
    // font-weight: 400;
    // line-height: 15px;
    // font-size: 12p
}




@media (max-width: 1024px) {
    
}

@media (max-width: 992px) {

}

@media (max-width: 576px) {

}


/*

.c-form {
    max-width: 430px;
    width: 100%;
    color: #fafafa;
    // box-shadow: 0 2px 4px rgb(0 0 0 / 24%), 0 4px 16px rgb(0 0 0 / 16%);
    border-radius: 2px;
    text-align: left;
}

.c-form.\--white {
    background-color: #fff;
    color: #222;
}


.c-form__title {
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    padding: 25px 10px;
    background-color: $accent-color
}



@media (max-width: 1024px) {
    .c-form__title{
        font-size: 18px;
    }
}

.c-form__descr {
    margin-top: 30px;
    color: #bcbfc4;
}

.c-form__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
}

.c-form__item:not(:first-child){
    margin-top: 20px;
}

.c-form__label {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 25px;
    font-size: 1rem;
    color: #2e3033;
    position: relative;
    margin-bottom: 0;
}

.c-form__input {
    padding: 8px 10px;
    margin-top: 5px;
    border: 1px solid #e1e2e6;
    background-color: #f2f3f5;
    border-radius: 2px;
    // box-shadow: inset 1px 1px 3px rgb(0 0 0 / 10%);
}

.c-form__input-error{
    position: absolute;
    bottom: -23px;
    right: 0;
    line-height: 1.2;
    font-size: 12px;
    color: #f99;
}

.c-form__content--nonpad .form_button {
    padding: 0 30px;
}

.c-form__btn {
    width: 100%;
    color: #fff;
    font-weight: 400;
    line-height: 1rem;
    font-size: 1rem;
    text-align: center;
    padding: 10px 5px;
    text-decoration: none;
    transition: all .2s;
    cursor: pointer;
    border: 1px solid #96151A;
    background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
    border-radius: 5px;
}

.c-form__btn:hover,
.c-form__btn:focus{
    background-color: transparent;
    color: $accent-color;
}

.c-form__conf {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: normal;
    font-size: 10px;
    color: #bcbfc4;
}

.section-promo__wrap-form {
    position: relative;
    z-index: 98;
    display: flex;
    justify-content: center;
}

#modal-callback.fancybox-content, 
#modal-callback .c-form__content
{
    padding: 0px;
}
.c-modal-form .c-form__label{
    padding: 20px;
    padding-bottom: 0px;
}


.c-modal-form .form_button{
    padding: 20px
}
.c-modal-form .c-form__conf{
    margin-top: 0px;
    padding-bottom: 20px;
    
}
// #modal-callback .c-form__item{
//     padding: 20px 0px;
// }




// @media (max-width: 992px) {

//     .section-promo .section-promo__wrap-form{
//         display: none;
//     }

// }

*/