.c-footer{
    background-color: #292929;
    padding: 20px 0px;
}

.c-footer__wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.footer__logo{
    width: 400px;
    color: #C4C4C4;;
    display: flex;
    align-items: center;
    font-size: 12px;
}

.footer__logo picture{
    padding-right: 5px;
    border-right: 1px solid #C4C4C4;
    width: 210px;
}

.footer__logo span{
    padding-left: 5px;
    line-height: 1.2;
    flex: 1;
}


.footer__contact{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.footer__tel{
    color: #FFF;
    font-weight: bold;
    font-size: 20px;
    margin-right: 10px;
}
.footer__tel:hover,
.footer__tel:focus{
    text-decoration: none;
    color: #0CB121;
}

.footer__btn{
    background-color: #fff;
    color: #0CB121;
    padding: 10px 15px;
    text-transform: uppercase;
}

.footer__btn:hover,
.footer__btn:focus{
    text-decoration: none;
    color: #fff;
    background-color: #0CB121;
}


.btn-scroll__link{
    width: 45px;
    height: 45px;
    border: 1px solid #959595;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
}

.privacy-policy__link {
    color: #FFF;
    font-size: 14px;
    width: 50%;
    margin-top: 10px;
}

.wbooster__link{
    margin-left: auto;
    margin-top: 10px;
}

.wbooster {
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 12px;
}
.wbooster__text {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #f7f7f7;
}


#privacy-policy{
    max-width: 70%;
    padding: 20px;
}

#privacy-policy p{
    line-height: 1.2;
}

#privacy-policy p:not(:last-child){
    margin-bottom: 8px;
}


@media(max-width: 992px){
    .footer__logo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    .footer__logo span{
        width: 170px;
        flex: initial;
    }

    .footer__contact{
        width: 100%;
        justify-content: center;
    }

    .btn-scroll__link{
        display: none;
    }
}

@media(max-width: 576px){
    .privacy-policy__link{
        width: 100%;
        text-align: center;
    }
    .footer__contact{
        flex-direction: column;
    }
    .wbooster__link{
        margin: 10px auto;
    }
}