.header{
    padding: 10px 0px;
    background-color: transparent;
    position: relative;
    z-index: 2;
    margin-bottom: 60px;
}

.header__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
   
.header__wraper-logo{
    flex-basis: 15%;
    width: 15%;
}

.header-icon__wrapper{
    flex-basis: 50%;
    width: 50%;
    display: flex;
    align-items: center;
}

.header-icon____item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-icon__text {
    text-align: center;
    font-size: 12px;
}



.header-contact__wrapper{
    flex-basis: 230px;
    width: 230px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.header__contact{
    text-align: right;
}

.header__phone{
    font-size: 24px;
    color: #FFF;
}

.header__phone:hover,
.header__phone:focus{
    color: #0CB121;
    text-decoration: none;
}

.header-btn__link{
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.02em;
    text-decoration: solid;
    border-bottom: 1px dashed;
}

.header-btn__link:hover,
.header-btn__link:focus{
    text-decoration: none;
    color: #0CB121;
    border-color: #0CB121;
}






@media (max-width: 1024px) {
    .header__menu {
        flex-basis: 52%;
        width: 52%;
    }
    .header__link {
        font-size: 13px;
    }
    .header__contact{
        display: flex;
        flex-direction: column;
    }
    .header__list-item {
        font-size: 14px;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 576px) {

}
